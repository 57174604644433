<template>
  <div id="myproduct" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Genarate QR</p>
          </div>
        </div>

        <div class="row m-0 p-4">
          <div class="col-12 col-xl-6 mb-2">
            <div class="form-group  row align-items-center">
              <label class="">สาขา</label>
              <div class="col-sm-10">
                <multiselect
                  placeholder="เลือกสาขา"
                  :disabled="disableStore"
                  :key="data.storeList"
                  v-model="data.storeList"
                  :options="storeList"
                  :clear-on-select="true"
                  :allow-empty="true"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  :custom-label="labelWithCity"
                  label="STCODE"
                  track-by="STCODE"
                >
                  <div
                    class="option__desc isDisabled"
                    slot="option"
                    slot-scope="props"
                  >
                    <span
                      >{{ props.option.STORE + " - " + props.option.STCODE }}
                    </span>
                  </div>
                </multiselect>
              </div>
            </div>
          </div>
          <!-- ---------------------------------- -->
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-2 text-right">หมวดหมู่</div>
              <div class="col-10">
                <div class="input-group   ">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectnow"
                    @click="CallModalfindbycatcode()"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" @click="Clearcatcode()">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------------------------------- -->
          <div class="col-12 ">
            <div class="btn-group my-3" role="group" aria-label="Basic example">
              <button
                v-for="(e, i) in modeList"
                :key="i"
                type="button"
                class="btn "
                :class="[e.key === mode ? 'bt-sub-main' : 'bt-main']"
                @click="selectMode(e.key)"
              >
                {{ e.name }}
              </button>
            </div>
            <!-- +------------- skulist --------- -->
            <div
              class="row form-inline mb-3 align-items-start"
              v-if="mode === 'skulist'"
            >
              <div class="col-6 col-xl-1 m-0 p-0 text-start">SKU List :</div>
              <div class="col-6 col-xl-10  ">
                <textarea
                  class="form-control w-100"
                  rows="3"
                  @blur="setskuList"
                  v-model="data.skuList"
                ></textarea>
                <span class="font-0-8s text-muted"
                  ><span class="font-700">Exp : </span>sku1,sku2,sku3
                  <span class="text-danger font-700"
                    >*** Sku list max 100 sku ***</span
                  ><span></span
                ></span>
              </div>
            </div>
            <!-- ------------- excel ------------- -->
            <div
              class="row justify-content-end my-4  "
              v-else-if="mode === 'excel'"
            >
              <div class="col-6 col-xl-2 text-xl-right">
                <div class="form-group form-group-sm  ">
                  <input
                    ref="fileupload"
                    type="file"
                    class="form-control-file form-control-file-sm"
                    @change="onChange"
                  />
                </div>
              </div>
              <div class="col-6 col-xl-3 text-xl-right">
                <button
                  type="button"
                  class="btn btn-sm bt mx-1"
                  :disabled="data.skuExcel.length == 0"
                  @click="Clear()"
                >
                  ยกเลิก
                </button>
                <button type="button" class="btn btn-sm bt-main mx-1">
                  <a :href="file" download class="text-white"> ไฟล์ตัวอย่าง</a>
                </button>
              </div>
              <!-- --------------------------- -->
              <div class="col-12">
                <p class="text-center font-600 font-1-2s">
                  {{
                    this.data.skuExcel === ""
                      ? ""
                      : this.data.skuExcel.length + " SKU"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- -------------------------------- -->
          <div class="col-12 text-center my-2 font-600 ">
            <button type="button" class="btn bt-main" @click="GenarateQR">
              Genarate QR
            </button>
          </div>
          <!-- ------------------------------ -->
          <div
            class="col-12 text-right my-2 font-600 "
            v-if="skuNA.length !== 0"
          >
            <button
              type="button"
              class="btn btn-danger"
              @click="export_data_fail"
            >
              นำออกข้อมูลที่ไม่สมบูรณ์ {{ skuNA.length }} รายการ
            </button>
          </div>
          <div
            class="col-12 my-2"
            v-if="mode === 'excel' && items.length !== 0"
          >
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="currentPage"
              :per-page="perPage"
              :tbody-tr-class="rowClass"
              hover
              outlined
              show-empty
              responsive
              :busy="$store.getters['enrichproductActions/get_onload']"
              class="font-0-8s"
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(enrich)="row">
                <!-- {{ row.item }} -->
                <span
                  v-if="
                    $serviceMain.chechnull(String(row.item.enrich).trim()) == ''
                  "
                >
                  <div
                    class="m-0 p-0 row w-100"
                    v-if="
                      $serviceMain.chechnull(
                        String(row.item.WrStatus).trim()
                      ) !== '' ||
                        $serviceMain.chechnull(
                          String(row.item.GpStatus).trim()
                        ) !== ''
                    "
                  >
                    <p class="m-0 p-0 w-100 ">
                      <span
                        class="badge font-500  "
                        :class="[
                          row.item.WrStatus == 'Y' ? 'badge-bnb' : 'badge-twd',
                        ]"
                        >CW</span
                      >
                      <span
                        class="badge font-500 "
                        :class="[
                          row.item.GpStatus == 'Y' ? 'badge-bnb' : 'badge-twd',
                        ]"
                        >GD</span
                      >
                    </p>
                  </div>
                </span>

                <span v-else>
                  <p class="badge   font-500 badge-main m-0 py-1  ">
                    {{ row.item.enrich }}
                  </p>
                </span>
              </template>

              <template #cell(Action)="row">
                <span
                  v-html="$svg_icon.edit"
                  v-if="$roleaction('Edit Enrich Product')"
                  type="button"
                  class="mr-3 text-muted"
                  v-b-tooltip.hover="'Write Content'"
                  @click="edit(row.item)"
                ></span>
              </template>
            </b-table>
            <div class="row justify-content-end">
              <div class="col-12 col-lg-6 col-xl-4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalfindbycatcode :key="reModalfindbycatcode" />
  </div>
</template>

<script>
import { getstore, auth, genqrcode } from "@/api/ProductInfo";
import { check_enrich } from "@/api/Product";
import Modalfindbycatcode from "@/components/common/Modalfindbycatcode.vue";
export default {
  components: { Modalfindbycatcode },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      file: require("@/files/skuInfoExam.xlsx"),
      reModalfindbycatcode: Date.now(),
      selectnow: this.$store.getters["categoryActions/get_category_remark"]
        .selectnow,
      storeList: [],
      data: { storeList: "", skuList: "", skuExcel: "", skuExcel_Good: "" },
      catcode: "",
      lvl: "",
      disableStore: true,
      mode: "skulist",
      modeList: [
        { key: "skulist", name: "SKU List" },
        { key: "excel", name: "Excel" },
      ],

      fields: [
        { key: "skCode", label: "skCode" },

        { key: "prNameTH", label: "prNameTH" },

        {
          key: "remark",
          label: "Remark",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      itmes_good: [],
      skuNA: [],
    };
  },
  watch: {
    getcategory: function(v) {
      this.selectnow = v.selectnow;
      this.lvl = v.lvl;
      this.catcode = v.catcode;
    },
    items: function(v) {
      this.totalRows = v.length;
      this.currentPage = 1;
    },
  },
  computed: {
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  mounted() {
    this.get_store();
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (String(item.remark).trim() !== "") return "table-danger";
    },
    edit(e) {
      let routeData = this.$router.resolve({
        name: "Enrich Product Detail",
        params: { skcode: e.skCode },
      });
      window.open(routeData.href, "_blank");
    },
    export_data_fail() {
      // console.log(this.skuNA);
      let date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}-${new Date().getTime()}`;

      let json = this.skuNA;
      let name = `Product_not_perfect(${date}).xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
    },
    async onChange(event) {
      try {
        let readfile = await this.$serviceMain.ReadExcel(event.target.files[0]);
        // console.log(readfile);
        let data = [];
        let data_good = [];
        this.items = [];
        if (readfile.length > 0) {
          for await (let v of readfile) {
            data.push(Number(v.SKCODE));
          }
        }

        this.data.skuExcel = data;
        let _skuNA = [];
        let sku_good = [];
        if (this.data.skuExcel.length > 0) {
          let data = { skCode: this.data.skuExcel.join(",") };
          let _check_enrich = await check_enrich(data);
          // console.log(_check_enrich);

          for await (let v of _check_enrich.data) {
            if (String(v.remark).trim() !== "") {
              _skuNA.push(v);
            } else {
              sku_good.push(v);
              data_good.push(v.skCode);
            }
          }
          this.skuNA = _skuNA;
          this.itmes_good = sku_good;
          this.items = [..._skuNA, ...sku_good];
          this.data.skuExcel_Good = data_good;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    async Clear() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการยกเลิกหรือไม่? / Do you want to cancel?"
      );
      if (confirm) {
        this.$refs.fileupload.value = null;
        this.data.skuExcel = "";
        this.data.skuExcel_Good = "";
        this.items = [];
        this.skuNA = [];
        this.itmes_good = [];
      }
    },
    selectMode(val) {
      this.mode = val;
      this.data.skuList = "";
      this.data.skuExcel = "";
      this.data.skuExcel_Good = "";
    },
    async getMyStore() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `user/getmystore`,
          {},
          1
        );
        // console.log(getAPI);
        // console.log(this.storeList);
        if (getAPI.data.length === 0) {
          throw `Please mapping store before use this function / โปรดเเมพสโตว์ก่อนใช้ฟังก์ชันนี้`;
        }

        let { store } = getAPI.data[0];
        let index = this.storeList.findIndex(
          (e) => String(e.STCODE).trim() === String(store).trim()
        );

        if (index !== -1) {
          // console.log(index);
          this.data.storeList = this.storeList[index];
          console.log(this.data.storeList);
        } else if (
          String(store)
            .toUpperCase()
            .trim() === "ALL"
        ) {
          console.log(store);
          this.disableStore = false;
        }

        // disableStore
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    labelWithCity(val) {
      return `${val.STORE} - ${val.STCODE}`;
    },
    async validate() {
      return new Promise((resolve, reject) => {
        let { storeList, skuList, skuExcel } = this.data;
        if (!!!storeList) {
          resolve("Please select store / กรุณาระบุสโตร์");
        }
        if (!!!this.catcode && !!!skuList && !!!skuExcel) {
          resolve(
            "Please enter catcode or sku / กรุณาระบุ เลขที่หมวดหมู่ หรือ เลขที่สินค้า"
          );
        }
        resolve("OK");
      });
    },
    async GenarateQR() {
      try {
        let vali = await this.validate();
        if (vali !== "OK") {
          throw vali;
        }
        let getAuth = await auth({});

        let { storeList, skuList, skuExcel, skuExcel_Good } = this.data;
        let { STCODE, STORE } = storeList;
        let SkCode =
          this.mode === "skulist"
            ? skuList === ""
              ? ""
              : skuList.join(",")
            : this.mode === "excel"
            ? skuExcel_Good.join(",")
            : "";
        let data = {
          StoreCode: STCODE,
          CatCode: this.catcode,
          SkCode: SkCode,
        };
        // params, host, token
        let getData = await genqrcode(
          data,
          process.env.VUE_APP_URL_TAX_INFO,
          getAuth.data
        );
        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        // var fileURL = window.URL.createObjectURL(new Blob([getData.data]));
        // var fileLink = document.createElement("a");

        // fileLink.href = fileURL;
        // fileLink.setAttribute(
        //   "download",
        //   `GenQR${STCODE === "" ? "" : `-${STCODE}`}-${Date.now()}.pdf`
        // );
        // document.body.appendChild(fileLink);
        // fileLink.click();
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    setskuList() {
      try {
        let { skuList } = this.data;

        let arr = String(skuList).split(`\n`);

        if (arr[0].trim().length > 8 && arr[arr.length - 1].trim() === "") {
          arr = String(skuList)
            .replace(/\n/g, "")
            .split(","); // skuList.split(",");
        }
        if (arr.length > 100) {
          this.data.skuList = "";
          throw `Sku list max 100 sku / รายการ Sku สูงสุด 100 SKU`;
        }
        this.data.skuList = arr;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_store() {
      try {
        let getData = await getstore();
        // console.log(getData);
        this.storeList = getData.data;
        this.getMyStore();
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    hide() {
      this.$bvModal.hide("Modalfindbycatcoderemark");
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
  },
};
</script>

<style></style>
