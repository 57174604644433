import { call_API, call_API_URL, call_API_Download_URL } from '../services/APIservice'
export async function getstore(params) {
    return new Promise(async (resolve, reject) => {
        try {
            let getAPI = await call_API(
                "post",
                `product-info/getstore`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function auth(params) {
    return new Promise(async (resolve, reject) => {
        try {

            let getAPI = await call_API(
                "post",
                `ms-chg/auth/product-info`,
                params,
                1
            );
            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function genqrcode(params, host, token) {
    return new Promise(async (resolve, reject) => {
        try {
            // para_method, para_url, para_data, getToken
            let getAPI = await call_API_Download_URL(
                "post",
                `${host}genqrcode/gen_qr`,
                params,
                token
            );
// --------------------- dowload --------------------
            var fileURL = window.URL.createObjectURL(new Blob([getAPI.data]));
            var fileLink = document.createElement("a");
            // console.log(fileURL);
            fileLink.href = fileURL;
            fileLink.setAttribute(
                "download",
                `GenQR${params.StoreCode === "" ? "" : `-${params.StoreCode}`}${params.CatCode === "" ? "" : `-${params.CatCode}`}-${Date.now()}.pdf`
            );
            document.body.appendChild(fileLink);
            fileLink.click();



            resolve(getAPI)
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}